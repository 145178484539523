import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const TagsPage = ({ data }) => (
  <Layout>
    <SEO title="Page two" />
    <h1>Hi from the second page</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
    <pre>
      Data!
      {JSON.stringify(data, false, 2)}
    </pre>
  </Layout>
);

export default TagsPage;

export const query = graphql`
  query {
    results: allContentfulContentPieces {
      People: distinct(field: primaryContact___name)
      Tags: distinct(field: tags)
    }
  }
`;
